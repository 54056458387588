export const changeBaloaPlaySubSectionsLoadingAction = ( new_state ) => {
    return { type: "BALOAPLAY:CHANGE_LOADING_SUB_SECTIONS", new_state }
}

export const loadBaloaPlaySubSectionsAction = ( data ) => {
    return { type: "BALOAPLAY:LOAD_SUB_SECTIONS", data }
}

export const loadBaloaPlaySubSectionsArrayAction = ( data, filter_type ) => {
    return { type: "BALOAPLAY:LOAD_SUB_SECTIONS_ARRAY", data, filter_type }
}

export const changeSearchMatchVideosLoadingAction = ( new_state ) => {
    return { type: "BALOAPLAY:CHANGE_SEARCH_MATCH_VIDEOS_LOADING", new_state }
}

export const loadSearchMatchVideosAction = ( data ) => {
    return { type: "BALOAPLAY:LOAD_SEARCH_MATCH_VIDEOS", data }
}

export const loadBaloaPlayMatchAction = ( data, visibility ) => {
    return { type: "BALOAPLAY:LOAD_BALOA_PLAY_MATCH", data, visibility }
}

export const changeBaloaPlayFullVideoModalStateAction = ( new_state ) => {
    return { type: "BALOAPLAY:CHANGE_FULL_VIDEO_MODAL_STATE", new_state }
}

export const loadBaloaPlayMatchClipsAction = ( data ) => {
    return { type: "BALOAPLAY:LOAD_BALOA_PLAY_MATCH_CLIPS", data }
}

export const changeBaloaPlayPaymentMessageModalStateAction = ( new_state ) => {
    return { type: "BALOAPLAY:CHANGE_PAYMENT_MESSAGE_MODAL_STATE", new_state }
}

export const changeBaloaPlayPaymentOptionsModalStateAction = ( new_state ) => {
    return { type: "BALOAPLAY:CHANGE_PAYMENT_OPTIONS_MODAL_STATE", new_state }
}

export const setMatchPaymentUrlAction = ( data ) => {
    return { type: "BALOAPLAY:SET_MATCH_PAYMENT_URL", data }
}

export const changeBaloaPlayClipsModalStateAction = ( new_state ) => {
    return { type: "BALOAPLAY:CHANGE_CLIPS_MODAL_STATE", new_state }
}

export const changeBaloaPlayClipsVideoModalStateAction = ( new_state ) => {
    return { type: "BALOAPLAY:CHANGE_CLIPS_VIDEO_MODAL_STATE", new_state }
}

/* export const changeBaloaPlayPaymentClipsOptionsModalStateAction = ( new_state ) => {
    return { type: "BALOAPLAY:CHANGE_PAYMENT_CLIPS_OPTIONS_MODAL_STATE", new_state }
} */

export const setClipPaymentUrlAction = ( data ) => {
    return { type: "BALOAPLAY:SET_CLIP_PAYMENT_URL", data }
}

export const changeBaloaPlayClipsLoadingAction = ( new_state ) => {
    return { type: "BALOAPLAY:CHANGE_LOADING_CLIPS", new_state }
}

export const loadBaloaPlayClipsAction = ( data ) => {
    return { type: "BALOAPLAY:LOAD_MY_CLIPS", data }
}

export const loadBaloaPlayClipAction = ( data ) => {
    return { type: "BALOAPLAY:LOAD_CLIP", data }
}

export const changeBaloaPlayClaimClipsOptionsModalStateAction = ( new_state ) => {
    return { type: "BALOAPLAY:CHANGE_CLAIM_CLIPS_OPTIONS_MODAL_STATE", new_state }
}

export const changeClaimClipLoadingAction = ( new_state ) => {
    return { type: "BALOAPLAY:CHANGE_LOADING_CLAIM_CLIPS", new_state }
}

export const changeBaloaPlayMyClipsVideoModalStateAction = ( new_state ) => {
    return { type: "BALOAPLAY:CHANGE_MY_CLIPS_VIDEO_MODAL_STATE", new_state }
}

export const changeBaloaPlayUpdateClipModalStateAction = ( new_state ) => {
    return { type: "BALOAPLAY:CHANGE_UPDATE_CLIP_MODAL_STATE", new_state }
}

export const changeBaloaPlayAddToPostModalStateAction = ( new_state ) => {
    return { type: "BALOAPLAY:CHANGE_ADD_TO_POST_MODAL_STATE", new_state }
}

export const changeBaloaPlayDeletePresentationVideoModalStateAction = ( new_state ) => {
    return { type: "BALOAPLAY:CHANGE_DELETE_PRESENTATION_VIDEO_MODAL_STATE", new_state }
}

export const setPostToDeleteAction = ( data ) => {
    return { type: "BALOAPLAY:SET_POST_TO_DELETE", data }
}

export const changeBaloaProWelcomeModalStateAction = ( new_state ) => {
    return { type: "BALOAPLAY:CHANGE_BALOA_PRO_WELCOME_MODAL_STATE", new_state }
}

export const changeBaloaPlayUnauthPlayModalStateAction = ( new_state ) => {
    return { type: "BALOAPLAY:CHANGE_UNAUTH_PLAY_MODAL_STATE", new_state }
}

export const changeBaloaPlayCreateClipWelcomeModalStateAction = ( new_state ) => {
    return { type: "BALOAPLAY:CHANGE_CREATE_CLIP_WELCOME_MODAL_STATE", new_state }
}

export const changeBaloaPlayCreateClipModalStateAction = ( new_state ) => {
    return { type: "BALOAPLAY:CHANGE_CREATE_CLIP_MODAL_STATE", new_state }
}

export const changeBaloaPlayCreateClipConfirmationModalStateAction = ( new_state ) => {
    return { type: "BALOAPLAY:CHANGE_CREATE_CLIP_CONFIRMATION_MODAL_STATE", new_state }
}

export const changeBaloaPlayMaxClipsInfoModalStateAction = ( new_state ) => {
    return { type: "BALOAPLAY:CHANGE_MAX_CLIPS_INFO_MODAL_STATE", new_state }
}

export const changeBaloaPlayShowCreateClipWelcomeStateAction = ( new_state ) => {
    return { type: "BALOAPLAY:CHANGE_SHOW_CREATE_CLIP_WELCOME_STATE", new_state }
}

export const changeBaloaPlayMatcPaymentConfirmationModalStateAction = ( new_state ) => {
    return { type: "BALOAPLAY:CHANGE_MATCH_PAYMENT_CONFIRMATION_MODAL_STATE", new_state }
}

export const loadBaloaPlayMatchPlansAction = ( data ) => {
    return { type: "BALOAPLAY:LOAD_MATCH_PLANS", data }
}

export const changeBaloaPlaySharePurchaseMatchLinkModalStateAction = ( new_state ) => {
    return { type: "BALOAPLAY:CHANGE_SHARE_PURCHASE_MATCH_LINK_MODAL_STATE", new_state }
}

export const changeBaloaPlayAcceptSharedMatchLinkModalStateAction = ( new_state ) => {
    return { type: "BALOAPLAY:CHANGE_ACCEPT_SHARED_MATCH_LINK_MODAL_STATE", new_state }
}

export const loadSharedLinkMatchInfoAction = ( data ) => {
    return { type: "BALOAPLAY:SHARED_LINK_MATCH_INFO", data }
}

export const changeBaloaPlayInvalidLinkModalStateAction = ( new_state ) => {
    return { type: "BALOAPLAY:CHANGE_INVALID_LINK_MODAL_STATE", new_state }
}

export const changeBaloaPlayMaxDownloadClipsInfoModalStateAction = ( new_state ) => {
    return { type: "BALOAPLAY:CHANGE_MAX_DOWNLOAD_CLIPS_INFO_MODAL_STATE", new_state }
}

export const loadBaloaPlayClipUrlAction = ( data ) => {
    return { type: "BALOAPLAY:LOAD_CLIP_URL", data }
}