/*eslint eqeqeq: "off"*/
// React
import React from 'react';
import { styled } from '@mui/material/styles';

// React device detect
import { isMobile } from 'react-device-detect';

// Styles
import './BaloaPlayMatchPaymentConfirmationModalComponent.styles.css';

// i18n
import { withTranslation } from 'react-i18next';

//Components
import ModalComponent from 'shared/components/modal/ModalComponent';

import LinearProgress, { linearProgressClasses } from '@mui/material/LinearProgress';
import PrimaryButtonComponent from 'shared/components/primary-button/PrimaryButtonComponent';

function BaloaPlayMatchPaymentConfirmationModalComponent( props ) {

    const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
        height: 10,
        borderRadius: 10,
        marginTop: 6,
        [`&.${linearProgressClasses.colorPrimary}`]: {
          backgroundColor: '#D9D9D9',
        },
        [`& .${linearProgressClasses.bar}`]: {
          borderRadius: 10,
          backgroundColor: 'var(--new-green-color)',
        },
    }));

    return(
        <ModalComponent
            isOpen={props.isOpen}
            onClose={props.onClose}
            className="baloa-play-match-payment-confirmation-modal"
        >
            <div className={`baloa-play-match-payment-confirmation-modal__container `}>
                <div className='baloa-play-match-payment-confirmation-modal__header'>
                    <span className='hubicon-play_circle'/>
                    <div className='baloa-names'>{props.t('matchPaymentConfirmationModal.title')}</div>
                </div>
                <div className='baloa-table subtitle'>{props.t('matchPaymentConfirmationModal.subTitle')}</div>
                
                <div className='baloa-names subtitle'>{props.t('matchPaymentConfirmationModal.purchaseSubTitle')}</div>
                
                {props.match_plans?.filter(plan => plan.generic_wompi_product === props.type_of_pay).map(plan =>
                    <React.Fragment>
                        <div className='baloa-play-match-payment-confirmation-modal__option-container'>
                            <div className='baloa-play-match-payment-confirmation-modal__option-header__title'>
                                <div className='baloa-names resumen'>{props.t('matchPaymentConfirmationModal.purchaseSelected')}</div>
                                <span className={`hub${plan.icon_class}`}/>
                                <div className='baloa-names'>{plan.name}</div>
                            </div>
                            <div className='baloa-table'>{props.t('matchPaymentConfirmationModal.includesLbl')}</div>
                            <div className="baloa-table lista" dangerouslySetInnerHTML={{
                                    __html: props.t('matchPaymentConfirmationModal.groupPlanListItem0Lbl',{_max_matches: plan.max_profiles_to_access})
                                }}></div>
                            <div className='baloa-table lista'>{props.t('matchPaymentOptionsModal.groupPlanListLbl')}</div>
                            {plan.features?.map(feature =>
                                <li className='baloa-table lista2'>{feature}</li>
                            )}
                            
                            <div className='baloa-play-match-payment-confirmation-modal__option-counter'>
                                <div className="baloa-table-column">{props.t('matchPaymentConfirmationModal.paymentLinkInfoLbl')}</div>                            
                            </div>
                            <div className='baloa-play-match-payment-confirmation-modal__separator'></div>
                            <div className='baloa-play-match-payment-confirmation-modal__option-total'>
                                <div className='baloa-table'>{props.t('matchPaymentConfirmationModal.totalLbl')}</div>
                                <div className='baloa-subtitle-2'>&nbsp;{`$${plan.value} ${plan.currency}`}</div>
                            </div> 
                        </div>
                        <PrimaryButtonComponent
                            input_type={'button'}
                            onClick={ ()=>{ props.handleOnPay( plan.generic_wompi_product ) } }
                        >
                            <span>{props.t('matchPaymentConfirmationModal.payBtnLbl')}</span>
                        </PrimaryButtonComponent>
                    </React.Fragment>
                )}
            </div>
        </ModalComponent>
    )
}

export default withTranslation('baloaplay')(BaloaPlayMatchPaymentConfirmationModalComponent);